import React from "react";
import {
    Dialog,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    CircularProgress,
} from "@material-ui/core";

interface LoadingDialogProps {
    show: boolean;
    message?: string;
}

export const LoadingDialog = ({show, message = "Loading..."}: LoadingDialogProps) => {
    return (
        <Dialog open={show} disableBackdropClick disableEscapeKeyDown>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "white" }}>
                            <CircularProgress />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={message} />
                </ListItem>
            </List>
        </Dialog>
    );
};

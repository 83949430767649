import { ReduxAction, GQLClientAction, GQLClientState } from "../base"

export const gqlclient = (state: GQLClientState = {}, action: ReduxAction<GQLClientAction, GQLClientState>): GQLClientState => {
    switch(action.type) {
        case GQLClientAction.SET_CLIENT: {
            const { client } = action.props
            return {
                ...state,
                client
            }
        }
        case GQLClientAction.CLEAR_CLIENT: {
            if(state.client){
                state.client.stop()
            }
            
            return {
                client: null
            }
        }
        default:
            return state
    }
}

import { CredentialAction, CredentialState, ReduxAction} from "../base"

export function setCredentials(userId: string, accessToken: string): ReduxAction<CredentialAction, CredentialState> {
    const action = {
        type: CredentialAction.SET_CREDENTIALS,
        props: {
            userId,
            accessToken
        }
    }
    return action
}

export function clearCredentials(): ReduxAction<CredentialAction, CredentialState> {
    const action = {
        type: CredentialAction.CLEAR_CREDENTIALS,
        props: {}
    }
    return action
}

import { createTheme } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

// reference:
// https://material-ui.com/customization/default-theme/

export const RaccoonBlueLight = "#8467ff"
export const RaccoonBlue = "#413af5"
export const RaccoonBlueDark = "#000bc1"

export const RaccoonGreen = green[400]

export const MaterialFoxTheme = createTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGrid: {
            item: {
                padding: "5px"
            }
        }
    },
    palette: {
        primary: {
            light: RaccoonBlueLight,
            main: RaccoonBlue,
            dark: RaccoonBlueDark,
            contrastText: '#fff',
        },
        secondary: {
            light: green[100],
            main: green[400],
            dark: green[700],
            contrastText: "#27282c",
        },
        text: {
            primary: "#27282c",
            secondary: "#949AA2"
        }
    }
})

export const FetchyColors = {
    primary: MaterialFoxTheme.palette.primary.main,
    secondary: MaterialFoxTheme.palette.secondary.main,
    lightText: MaterialFoxTheme.palette.text.secondary,
    darkText: MaterialFoxTheme.palette.text.primary
}

export const FetchyStyles = {
    criticalButton: {
        backgroundColor: "#d32f2f",
        color: "#fff"
    },
    informationButton: {
        backgroundColor: "#4871B8",
        color: " #fff"
    },
    highlightText: {
        color: "#F53A91"
    },
    subtleText: {
        color: "#949AA2"
    }
}




import React from "react"

import {
    Snackbar,
    LinearProgress,
    Typography,
    Card,
    CardContent,
    SnackbarOrigin
} from "@material-ui/core"


type ProcessingSnackbarProps = {
    label: string,
    open: boolean,
    position?: SnackbarOrigin
}

export const ProcessingSnackbar = (props: ProcessingSnackbarProps) => {

    let anchorOrigin: SnackbarOrigin = {
        horizontal: "right",
        vertical: "bottom",
    }
    if(props.position) {
        anchorOrigin = props.position
    }

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={props.open}>
            <Card style={{ width: "300px", backgroundColor: "#263238" }}>
                <CardContent>
                    <Typography
                        align="left"
                        style={{ color: "#ffffff" }}
                        variant="body2">
                        {props.label}
                    </Typography>
                </CardContent>
                <LinearProgress />
            </Card>
        </Snackbar>
    );
}

import { ReduxAction, GQLClientAction, GQLClientState } from "../base"
import { FetchyGQLClient } from "../../fetchyql/base";

export function setGQLClient(client: FetchyGQLClient): ReduxAction<GQLClientAction, GQLClientState> {
    const action = {
        type: GQLClientAction.SET_CLIENT,
        props: {
            client
        }
    }
    return action
}

export function clearGQLClient(): ReduxAction<GQLClientAction, GQLClientState> {
    const action = {
        type: GQLClientAction.CLEAR_CLIENT,
        props: {}
    }
    return action
}

import { ServiceWorkerAction, ServiceWorkerState, ReduxAction } from "../base"


export function serviceWorkerInitialize(): ReduxAction<ServiceWorkerAction, ServiceWorkerState> {
    const action: ReduxAction<ServiceWorkerAction, ServiceWorkerState> = {
        type: ServiceWorkerAction.SW_REGISTER,
        props: {}
    }
    return action
}

export function serviceWorkerSuccess(): ReduxAction<ServiceWorkerAction, ServiceWorkerState> {
    const action: ReduxAction<ServiceWorkerAction, ServiceWorkerState> = {
        type: ServiceWorkerAction.SW_INIT,
        props: {}
    }
    return action
}


export function serviceWorkerUpdateTimeout(): ReduxAction<ServiceWorkerAction, ServiceWorkerState> {
    const action: ReduxAction<ServiceWorkerAction, ServiceWorkerState> = {
        type: ServiceWorkerAction.SW_UPDATE_TIMEOUT,
        props: {}
    }
    return action    
}

export function serviceWorkerHasUpdate(registration: any): ReduxAction<ServiceWorkerAction, ServiceWorkerState> {
    const action: ReduxAction<ServiceWorkerAction, ServiceWorkerState> = {
        type: ServiceWorkerAction.SW_UPDATE,
        props: {
            registration
        }
    }
    return action
}
import { CredentialAction, CredentialState, ReduxAction } from "../base"


export const credentials = (state: CredentialState = {}, action: ReduxAction<CredentialAction, CredentialState>): CredentialState => {
    switch(action.type) {
        case CredentialAction.SET_CREDENTIALS: {
            const { userId, accessToken } = action.props
            return {
                ...state,
                userId,
                accessToken,

            }
        }
        case CredentialAction.CLEAR_CREDENTIALS: {
            return {
                userId: null,
                accessToken: null
            }
        }
        default:
            return state
    }
}

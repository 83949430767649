import { combineReducers } from "redux"
import { credentials } from "./credentials"
import { gqlclient } from "./gqlclient"
import { session } from "./session"
import { serviceworker } from "./serviceworker"

export default combineReducers({
    credentials,
    gqlclient,
    session,
    serviceworker
})
// this is the loading screen that's displayed when new pages are lazy loaded

import React from "react"

import {
    Grid,
    CircularProgress
} from "@material-ui/core"
import { withTheme } from "@material-ui/core/styles"


export const SuspenseScreen = withTheme(() => {
    return (
        <Grid container justifyContent="center" alignItems="center" direction="row" style={{ height: "100vh" }}>
            <Grid item>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item><CircularProgress color="primary" /></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})
import {
    ApolloError,
    ApolloClient,
    NormalizedCacheObject
} from "@apollo/client"

import {
    DocumentNode
} from "graphql/language/ast"


export type FetchyQLErrorResponse = {
    __typename: string
    error: {
        message: string
        code: number
    }
}

export type FetchyQLResponse<T> = T | FetchyQLErrorResponse


export type FetchyGQLClient = ApolloClient<NormalizedCacheObject>

export function generateBasicAuth(username: string, password: string) {
    const token = Buffer.from(`${username}:${password}`).toString("base64");
    return `Basic ${token}`
}


export async function executeGraphQLMutationAsync<T>(client: FetchyGQLClient, mutation: DocumentNode, params: Record<string, any> = {}): Promise<T> {

    let data: any
    try {
        const response = await client.mutate({
            mutation,
            variables: params
        })
        data = response.data
    }
    catch(error) {
        // a hard error, network related, an impropely structured graphQL query, or an unhandled exception in backend
        throw new FetchyQLNetworkError(error)
    }

    let [ , payload ]: [string, Record<string, any>] = Object.entries(data)[0]
    if(payload.__typename === "FetchyGQLRequestErrorField") {
        // an application error, caused by bad request params or a handled server side exception
        const error = payload as FetchyQLErrorResponse
        throw new FetchyQLRequestError(error)
    }
    else {
        return payload as T
    }    
}

export async function executeGraphQLQueryAsync<T>(client: FetchyGQLClient, query: DocumentNode, params: Record<string, any> = {}): Promise<T> {

    let data: any
    try {
        const response = await client.query({
            query,
            variables: params,
            fetchPolicy: "network-only"
        })
        data = response.data
    }
    catch(error) {
        // a hard error, network related, an impropely structured graphQL query, or an unhandled exception
        throw new FetchyQLNetworkError(error)
    }

    let [ , payload ]: [string, Record<string, any>] = Object.entries(data)[0]
    if(payload.__typename === "FetchyGQLRequestErrorField") {
        // an application error, caused by bad request params or a handled server side exception
        const error = payload as FetchyQLErrorResponse
        throw new FetchyQLRequestError(error)
    }
    else {
        return payload as T
    }
}


export enum FetchyQLErrorType {
    REQUEST_ERROR = "FetchyQLRequestError",
    NETWORK_ERROR = "FetchyQLNetworkError"
}


export class FetchyQLRequestError extends Error {
    readonly name: string = FetchyQLErrorType.REQUEST_ERROR
    errorCode: number
    message: string
    error: string

    constructor(error: FetchyQLErrorResponse, message?: string) {
        super()
        this.message = message ? message : `${error.error.code}: ${error.error.message}`
        this.error = error.error.message
    }
}


export class FetchyQLNetworkError extends Error {
    readonly name: string = FetchyQLErrorType.NETWORK_ERROR
    statusCode: number
    message: string
    errors: Array<{
        message: string
    }>

    constructor(error: ApolloError) {
        super()
        this.message = error.message

         // "type narrowing"
        // https://github.com/apollographql/apollo-link/issues/816
        if(error.networkError && "statusCode" in error.networkError) {
            this.statusCode = error.networkError.statusCode
            
            if("result" in error.networkError) {
                // ServerError
                this.errors = error.networkError.result.errors.map(error => error.message)
            }
            else {
                // ServerParseError
                this.errors = [{message: error.networkError.bodyText}]
            }
        }
        else {
            // Error
            if(error.networkError) {
                this.errors = [{message: error.networkError.stack}]
            }
            else {
                this.errors = [{message: `${error.name} -- ${error.message}`}]
            }
            
            this.statusCode = 0
        }
    }
}
import { gql } from "@apollo/client"

export const GQL_KIOSK_PING = gql`
    query ping {
        kioskPing {
            __typename
            ... on FetchyConfirmSuccessField {
                code
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }`

export const GQL_KIOSK_SENSOR_NETS = gql`
    query kioskNets($airportId: String!) {
        kioskSensorNets(airportId: $airportId) {
            __typename
            ... on AirportSensorNetsField {
                name
                nets {
                    name
                    netId
                    snapshot {
                        crowdEstimate
                        dwellSecondsEstimate
                    }
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
            
        }
    }
`


export const GQL_KIOSK_REQUEST_CHECK = gql`
    query verifyAccessRequest($airportId: String!, $deviceId: String!) {
        kioskCheckAccess(airportId: $airportId, deviceId: $deviceId) {
            __typename
            ... on CredentialsField {
                accountId
                accessToken
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }
`

export const GQL_KIOSK_REQUEST = gql`
    query accessRequest($airportId: String!) {
        kioskAccessRequest(airportId: $airportId) {
            __typename
            ... on CredentialsField {
                accountId
                accessToken
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }
`

export const GQL_ADMIN_LOGIN = gql`
    query adminLogin {
        adminToken {
            __typename
            ... on CredentialsField {
                accountId
                accessToken
            }
            ...on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }`


export const GQL_ADMIN_LOGOUT = gql`
    query logout {
        adminSignout {
            __typename,
            ... on FetchyConfirmSuccessField {
                message
                code
            }
            ...on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }        
        }
    }`


export const GQL_GET_AIRPORTS = gql`
    query getAirports {
        adminAirports {
            __typename
            ... on AirportListField {
                airports {
                    airportId
                    name
                    iataCode
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }`

export const GQL_GET_SENSORS = gql`
    query getSensors {
        adminSensors {
            __typename
            ... on SensorListField {
                sensors {
                    hwId,
                    hwName
                    sensorId,
                    airportId,
                    name,
                    active,
                    timezone,
                    firstSeenUtc
                    lastSeenUtc
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message,
                    code
                }
            }
        }
    }`

export const GQL_GET_ADMIN_ACCOUNTS = gql`
    query getAdminAccounts {
        adminListAccounts {
            __typename
            ... on TeamDetailsField {
                pendingMembers {
                    email
                    roleId
                    teamId
                    createdAt
                    expiresOn
                }
                activeMembers {
                    teamId
                    userId
                    roleId
                    roleName
                    airportName
                    email
                    pictureUri
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                } 
            }
        }
    }`


export const GQL_MERCHANT_ACCOUNTS = gql`
    query adminGetMerchantAccounts {
        adminMerchantAccounts {
            __typename
            ... on TeamDetailsField {
                pendingMembers {
                    email
                    accountId
                    roleId
                    teamId
                    createdAt
                    expiresOn
                }
                activeMembers {
                    teamId
                    userId
                    roleId
                    roleName
                    airportName
                    email
                    pictureUri
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                } 
            }            
        }
    }
`

export const GQL_GET_ADMIN_ROLES = gql`
    query getRoles {
        adminTeamRoles {
            __typename
            ... on RoleListField {
                roles {
                    roleId
                    name
                    displayName
                    description
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }`

export const GQL_MERCHANT_ROLES = gql`
    query getAdminMerchantRoles {
        adminMerchantRoles {
            __typename
            ... on RoleListField {
                roles {
                    roleId
                    name
                    displayName
                    description
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }           
        }
    }
`

export const GQL_GET_KIOSK_NET_DATA = gql`
    query getKioskNetData($netId: String) {
        kioskSensorNetData(netId: $netId) {
        __typename
        ... on SensorNetViewField {
            netId
            name
            snapshot {
                crowdEstimate
                dwellSecondsEstimate
            },
            history {
                crowdHistory {
                    startUtc
                    value
                }
                dwellSecondsHistory {
                    startUtc
                    value
                }
            }
        }
        ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }`


export const GQL_VIEW_APPLICATION = gql`
    query viewApplication($merchantId: String) {
        adminViewApplicant(merchantId: $merchantId) {
            __typename
            ... on ApplicationField {
                fetchyUuid
                birthdate
                selfie
                phoneNumber
                fullname
                username
                state
                stripe {
                    accountId
                    taxidProvided
                    tosAccepted
                    platformPayments
                    payoutsEnabled
                    missingInfo
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }
`

export const GQL_GET_AIRPORT_DETAILS = gql`
    query getAirportDetails($airportId: String) {
        adminAirport(airportId: $airportId) {
            __typename
            ... on AirportDetailsField {
                details {
                    airportId
                    name
                    iataCode
                    utcOffset
                    dbTzString
                    primaryIso4217
                    salesTaxRate
                    active
                    deliveryService
                    xtermDeliveryService
                }
                terminals {
                    terminalId
                    terminalName
                    layoutInfo
                }
                teams {
                    teamId
                    displayName
                    description
                    variant
                }
            }
            ... on FetchyGQLRequestErrorField {
                error {
                    message
                    code
                }
            }
        }
    }
`